import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import logo from "./assets/logo.png"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import hero from "./assets/hero.png";

import web3ModalSetup from './helpers/web3ModalSetup';
import Web3 from "web3";
import getAbi from './ABI';
import { useState, useEffect, useCallback } from 'react';
import React from 'react';
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import {  CurrencyExchange,  Twitter, Wallet } from 'react-bootstrap-icons';
import { Telegram } from 'react-bootstrap-icons';
import { Discord } from 'react-bootstrap-icons';

import pancakeswap from "./assets/pancakeswap.png";

import dapp from "./assets/dapp.png";
import dapprader from "./assets/dapprader.webp"
import dextool from "./assets/dextool.png"
import poocoin from "./assets/poocoin.png"

import './App.css';


const web3Modal = web3ModalSetup();


const App = () => {

  const [Abi, setAbi] = useState();
  const [web3, setWeb3] = useState();
  const [isConnected, setIsConnected] = useState(false);
  const [injectedProvider, setInjectedProvider] = useState();
  const [refetch, setRefetch] = useState(true);
  // eslint-disable-next-line
  const [accounts, setAccounts] = useState(null);
  const [current, setCurrent] = useState(null);
  const [connButtonText, setConnButtonText] = useState("CONNECT");

  const [open, setOpen] = React.useState(false);
  const [balance, setBalance] = useState(0);
  const [value,setValue] = useState(0);
  const [message,setMessage] = useState('');
  // eslint-disable-next-line
  const [chainid, setChainId] = useState(56);
  const [tokenBalance,setTokenBalance] = useState(0);
  const [total_sale,setTotalSale] = useState(0);





  const handleToClose = (event, reason) => {
    if ("clickaway" === reason) return;
    setOpen(false);
  };




  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (
      injectedProvider &&
      injectedProvider.provider &&
      typeof injectedProvider.provider.disconnect == "function"
    ) {
      await injectedProvider.provider.disconnect();
    }
    setIsConnected(false);

    window.location.reload();
  };
  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new Web3(provider));
    const acc = provider.selectedAddress
      ? provider.selectedAddress
      : provider.accounts[0];


    const short = shortenAddr(acc);

    setWeb3(new Web3(provider));
    setAbi(await getAbi(new Web3(provider)));
    setAccounts([acc]);
    setCurrent(acc);
    //     setShorttened(short);
 
    setIsConnected(true);

    setConnButtonText(short);

    provider.on("chainChanged", (chainId) => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new Web3(provider));
      
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new Web3(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });

  
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  useEffect(() => {
    setInterval(() => {
      setRefetch((prevRefetch) => {
        return !prevRefetch;
      });
    }, 10000);
  }, []);

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
    // eslint-disable-next-line
  }, []);

  const shortenAddr = (addr) => {
    if (!addr) return "";
    const first = addr.substr(0, 3);
    const last = addr.substr(38, 41);
    return first + "..." + last;
  };

 




  const ChainCheckList = async () => {
    if (isConnected) {
    let chainid = await web3.eth.getChainId();
    setChainId(chainid)
    if(chainid !== 56) {
      
      if (window.ethereum.networkVersion !== '56') {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex('56') }]
          });
        } catch (err) {
            // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName: 'BNB SMART CHAIN',
                  chainId: web3.utils.toHex('56'),
                  nativeCurrency: { name: 'Binance', decimals: 18, symbol: 'BNB' },
                  rpcUrls: ['https://bsc.publicnode.com']
                }
              ]
            });
          }
        }
      }
    }
    else {
      
    }
    }
  }

  const Statistics = async () => {
    if (isConnected && Abi) {
      let balance = await web3.eth.getBalance(current);
      setBalance(balance / 1e18);
      let tokenBalance = await Abi.methods.balanceOf(current).call();
      setTokenBalance(tokenBalance / 1e18);
      let total_sale = await Abi.methods.total_sale().call();
      setTotalSale(total_sale);
  

    }
  }

  useEffect(() => {
    Statistics();

    ChainCheckList();
    // eslint-disable-next-line
  }, [isConnected, web3, Abi, refetch]);

  const Deposit = async (e) => {
    e.preventDefault()
    setOpen(true)
    if(value !== 0) { 
    setMessage("Buying DEXF");
    let _value = await web3.utils.toWei(value);
   
    await Abi.methods.PreSale().send({
     from: current,
     value: _value,
    })
    Statistics();

    setOpen(true)
    setMessage("Buy Successfull");
  }
  else {
    setMessage("Empty Field");
  }
 }









const theme = createTheme({
  components: {
    MuiSnackbar: {
      variants: [
        {
          props: { variant: "trouble" },
          style: {
            "& .MuiSnackbarContent-root": {
              background: "#fff",
              border: "1px solid #333",
              color: "#333",

            }
          }
        }
      ]
    }
  }
});














  return (
    <>
      <Navbar expand="lg" variant='dark' style={{ background: "black" }}>
        <Container fluid>
          <Navbar.Brand href="#"><img src={logo} alt="dexfactory" width={220} /></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >


            </Nav>
            <div className="d-flex">

              <Button variant="outline-warning btn-lg justifyX" style={{ borderRadius: "50px", color: "#fff", fontWeight: "bold" }} onClick={loadWeb3Modal}><Wallet /> {connButtonText}</Button>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className='heroSection'>
        <div className='container'>
          <br />
          <div className='row'>
            <div className='col-sm-6'><br /><br /><br /><br />
              <h1 style={{ fontSize: "72px", fontWeight: "bold" }} className='text-white'> DEXFACTORY, <br />PRE-SALE.</h1>
              <h2 className='text-white'>Stake DexFactory LP Token and Earn Daily DexFactory Token.</h2>
              <h4 className='text-white'>DexFactory is a decentralized Staking Protocol with 0% Fees</h4>
       <br /><br /><button className='btn btn-warning' style={{marginBottom:"5px"}}><a href="https://twitter.com/dexfactory" style={{textDecoration: "none",color:"#fff",fontWeight:"bold"}}><Twitter  style={{fontSize:"32px", color:"#4aaee7"}}/> TWITTER</a></button> <button className='btn btn-warning' style={{marginBottom:"5px"}}><a href="https://t.me/dexfactory" style={{textDecoration: "none",color:"#fff",fontWeight:"bold"}}><Telegram  style={{fontSize:"32px", color:"#229ED9"}}/> TELEGRAM</a></button> <button className='btn btn-warning' style={{marginBottom:"5px"}}><a href="https://bscscan.com/address/0x3487a69bEdfDeF7373526C35f9150792dAabAdF6#code" style={{fontWeight:"bold",color:"#fff",textDecoration:"none"}}><img src="https://bscscan.com/images/logo-bscscan.svg" alt="BSCSCAN" className='img-fluid' width={140}/></a></button>
                 </div>
            <div className='col-sm-6'>
           <img src={hero} alt="" className='img-fluid imgcontrol' width={500} />
            
            </div>
          </div>
          
          <br />
        </div>
      </div>

      {/* DEPOSIT SECTION */}


      <div className='depositSection'>
        <div className='container'>

          <br /> <br />
          <div className='depoSection'>
       

            <div className='row'>
              <div className='col-sm-3'></div>
              <div className='col-sm-6'>
                <center>
                  <div className='card depositCard' style={{ borderRadius: "30px", background: "#f4f4f4", border: "none" }}>
                    <div className='card-body'>
                      <center><h1 style={{ fontWeight: "bold" }}>PRE-SALE</h1></center>
                      <br />

                      <table className='table'>
                        <tbody>
                        <tr>
                            <td><h5 style={{ fontWeight: "bold" }}>TOTAL DEXF FOR PRE-SALE</h5></td>
                            <td style={{ textAlign: "right" }}><h5 style={{ fontWeight: "bold" }}>1,000,000</h5></td>
                          </tr>
                          <tr>
                            <td><h5 style={{ fontWeight: "bold" }}>BALANCE</h5></td>
                            <td style={{ textAlign: "right" }}><h5 style={{ fontWeight: "bold" }}><img src="https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=024" alt="BNB" width={35} /> {Number(balance).toFixed(4)} BNB</h5></td>
                          </tr>
                          <tr>
                            <td><h5 style={{ fontWeight: "bold" }}>DEXF BALANCE</h5></td>
                            <td style={{ textAlign: "right" }}><h5 style={{ fontWeight: "bold" }}><img src="https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=024" alt="BNB" width={35} /> {Number(tokenBalance).toFixed(2)} DEXF</h5></td>
                          </tr>
                          <tr>
                            <td><h5 style={{ fontWeight: "bold" }}>MIN BUY</h5></td>
                            <td style={{ textAlign: "right" }}><h5 style={{ fontWeight: "bold" }}><img src="https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=024" alt="BNB" width={35} /> 0.1 BNB</h5></td>
                          </tr>

                          <tr>
                            <td><h5 style={{ fontWeight: "bold" }}>DEXF PRICE</h5></td>
                            <td style={{ textAlign: "right" }}><h5 style={{ fontWeight: "bold" }}><img src="https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=024" alt="BNB" width={35} /> 0.00005 BNB</h5></td>
                          </tr>

                          <tr>
                            <td><h5 style={{ fontWeight: "bold" }}>HARD-CAP</h5></td>
                            <td style={{ textAlign: "right" }}><h5 style={{ fontWeight: "bold" }}><img src="https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=024" alt="BNB" width={35} /> {Number(total_sale / 1e18).toFixed(2)}/50 BNB</h5></td>
                          </tr>
                       
                        
                        </tbody>
                      </table>

                      <input type='number' placeholder="0.1 BNB" className='form-control' style={{ background: "#d9d9d9", textAlign: "center", fontWeight: "bold", height: "60px", marginBottom: "10px" }} onChange={(e) => setValue(e.target.value)}/>
                      <center><button className='btn btn-warning btn-lg btgx' style={{ width: "50%", borderRadius: "20px" }} onClick={Deposit}><CurrencyExchange /> BUY DEXF</button></center>
                      <table className='table'>
                      <tr>
                            <td><h5 style={{ fontWeight: "bold" }}>TOTAL DEXF</h5></td>
                            <td style={{ textAlign: "right" }}><h5 style={{ fontWeight: "bold" }}>{Number(value / 0.00005).toFixed(4)} DEXF</h5></td>
                          </tr>
                      </table>

                    </div>

                  </div>
                </center>
              </div>
             

            </div>

            <br />

            <div className='row'>
              <div className='col-sm-3'></div>
              <div className='col-sm-6'>
                <center>
                  <div className='card depositCard' style={{ borderRadius: "30px", background: "#f4f4f4", border: "none" }}>
                    <div className='card-body'>
                      <center><h1 style={{ fontWeight: "bold" }}>SWAP</h1></center>
                      <br />
                     <h3 className='fw-bold'>SWAP DEXF TO $DEXF</h3>
                      <input type='number' placeholder="0.00 DEXF AMOUNT" className='form-control' style={{ background: "#d9d9d9", textAlign: "center", fontWeight: "bold", height: "60px", marginBottom: "10px" }} onChange={(e) => setValue(e.target.value)}/>
                      <center><button className='btn btn-warning btn-lg btgx' style={{ width: "50%", borderRadius: "20px" }} onClick={Deposit}><CurrencyExchange /> SWAP DEXF</button></center>
                      <table className='table'>
                      <tr>
                            <td><h5 style={{ fontWeight: "bold" }}>TOTAL $DEXF GET</h5></td>
                            <td style={{ textAlign: "right" }}><h5 style={{ fontWeight: "bold" }}>{Number(value / 0.00005).toFixed(4)} $DEXF</h5></td>
                          </tr>
                      </table>

                    </div>

                  </div>
                </center>
              </div>
             

            </div>
            <br />
            <h1 className='fw-bold text-center'>DISTRIBUTION OF BNB</h1>
            <hr />
            <div className='row text-center'>
              <div className='col-3'>
                <h3>MARKETING</h3>
                <h1>20%</h1>
              </div>
              <div className='col-3'>
                <h3>TEAM</h3>
                <h1>1%</h1>
              </div>
              <div className='col-3'>
                <h3>LIQUIDITY</h3>
                <h1>76%</h1>
              </div>
              <div className='col-3'>
                <h3>DEVELOPMENT</h3>
                <h1>3%</h1>
              </div>
            </div>


            <br />
            <hr />
            <br />
            <div className='row'>
              <div className='col-sm-12'>
              <h3>WHAT IS DEXF AND $DEXF?</h3>
                <h5>DEXF TOKEN IS FOR THE PRE-SALE. WHERE YOU WILL GET WHEN YOU PARTICIPATE IN PRE-SALE. <br />
                $DEXF IS THE MAIN TOKEN. WHICH WILL BE TRADED ON PANCAKESWAP WITH BNB. 
                </h5>
                <hr />
                <h3>HOW TO SWAP YOUR BNB TO $DEXF?</h3>
                <h5>YOU CAN ONLY SWAP DEXF TO $DEXF. WHEN THE PRE-SALE IS OVER. 
                </h5>

                <hr />
                <h3>IS $DEXF IS EQUILENT TO DEXF?</h3>
                <h5>NO, THE SUPPLY OF $DEXF is 1 MILLION. YOU WILL GET FOR 1 $DEXF = 1.666 DEXF. 400K WILL BE ADDED TO LIQUIDITY and 600K WILL BE DISTRIBUTED TO PRE-SALE INVESTORS THROUGH SWAP PROGRAM.
                </h5>
              </div>
            </div>
            <br />

          </div>
        </div>
      </div>
      {/* Trades Section */}
      <br /> <br />

    

      {/* INFO */}

      
      <div className='exchangesSection container'>
        <br />
        <div className='card' style={{border:"none"}}>
          <div className='card-body'>
          <center> <h1 style={{fontWeight:"bold"}}>LISTING EXCHANGE</h1>
            
              </center>
              <div className='row'>
               
                <div className='col-sm-12'>
                  <img src={pancakeswap} alt="pancakeswap Dexfactory" className='img-fluid' />
                </div>

               
              </div>

              <center> <h1 style={{fontWeight:"bold"}}>Our Listing Partners</h1> <br />
              <div className='row'>
               

                <div className='col-sm-6'>
                  <img src={dapprader} alt="dapprader Dexfactory" className='img-fluid' />
                </div>

                <div className='col-sm-6'>
                  <img src={dapp} alt="dapp.com Dexfactory" className='img-fluid' />
                </div>

              </div>
              <br />
              <h1 style={{fontWeight:"bold"}}>Our Future Partners</h1> <br />
              <div className='row'>
                <div className='col-sm-6'>
                  <img src={poocoin} alt="poocoin Dexfactory" className='img-fluid' width={400}/>
              
                </div>

                <div className='col-sm-6'> <br /><br />
                  <img src={dextool} alt="dextool Dexfactory" className='img-fluid' />
                </div>

            

              </div>
            
              </center>
          </div>
        </div>

        <br />
       <br />
 
  
      </div>
      <br />
      <div className='footerSection'>
        <br /> <br />
        <div className='footerWidth'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>
                <h1 className='text-white' style={{ fontWeight: "bold" }}>DEXFACTORY</h1>
               
                <h2 className='text-white' style={{ fontWeight: "bold" }}>Contact Us</h2>
                <a href='https://twitter.com/dexfactory' style={{textDecoration:"none",color:"#fff",fontWeight:"bold"}}><Twitter  style={{color:"#fff"}}/> TWITTER</a> - <a href="https://t.me/dexfactory" style={{textDecoration:"none",color:"#fff",fontWeight:"bold"}}><Telegram style={{color:"#fff",fontWeight:"bold"}} /> TELEGRAM</a> - <a href="https://discord.gg/7AvN4saA"  style={{textDecoration:"none",color:"#fff",fontWeight:"bold"}}><Discord style={{color:"#fff"}} /> DISCORD</a> <br /> <br />
              </div>
            </div>

          </div>
        </div>
      </div>
      <ThemeProvider theme={theme}>
      <Snackbar
        anchorOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        variant="trouble"
        open={open}
        autoHideDuration={5000}
        message={message}
        onClose={handleToClose}
        action={
          <React.Fragment>
            <IconButton
              size="large"
              aria-label="close"
              color="inherit"
              onClick={handleToClose}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </React.Fragment>
        }
        
      />
      </ThemeProvider>

    </>
  );
}

export default App;
